.s-thank-you-ordering {
	margin-bottom: 62px
}

.thank-you-ordering {
	&__contact {
		margin: 32px 0;
	}
	&__phone, &__mail {flex-shrink: 0}
	&__phone {margin-right: 52px}
}