.s-result-search {
	padding: 0 0 48px;
	&__title {color: $text-dark}
	.row {
		.col-12, .col-lg-9, .col-md-8 {position: static !important}
	}
}

.info-search {
	&__title {color: $text-lgh}
	&__submenu {
		display: none;
		flex: 1;
		margin-right: 30px;
		background: $white;
		color: $text-lgh;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
	}
	&__submenu-title {
		padding: 16px 20px;
		background: #f3f2f1;
		border-radius: 55px;
		cursor: pointer;
		svg {
			fill: none;
			stroke: $text-lgh;
			width: 11px;
			height: 18px;
			stroke-width: 2px;
			transform: rotate(90deg);
			transition: .3s ease-out;
		}
		&.active {
			svg {
				stroke: $accent;
				transform: rotate(-90deg);
			}
		}
	}
}

@media only screen and (max-width : 768px) {
	.s-result-search {
		padding: 0 0 24px;
		&__title {width: 64%}
	}
	.info-search {
		align-items: flex-start !important;
		&__title {display: none}
		&__submenu {display: block}
	}
}

@media only screen and (max-width : 576px) {
	.s-result-search {
		&__title {width: 100%}
	}
	.info-search {
		flex-direction: column;
		&__submenu {
			order: 2;
			width: 100%;
			margin: 0;
		}
		.result {
			justify-content: space-between;
			order: 1;
			width: 100%;
			margin-bottom: 8px;
		}
    }
}