.basket-null {
	&__buttons {margin-top: 24px}
	&__button {
		&:not(:last-child) {margin-right: 40px}
	}
}

@media only screen and (max-width : 576px) {
	.basket-null {
		&__buttons {margin-top: 24px}
		&__button {
			&:not(:last-child) {
				margin-bottom: 16px;
				margin-right: 0;
			}
		}
	}
}