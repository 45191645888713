.result-search {
	&__title {color: $text-dark}
	&__submenu-title {
		margin-bottom: 20px;
		color: $text-dark;
		font-size: 18px;
		font-weight: 500;
		line-height: 150%;
	}
	&__view {margin-bottom: 15px}
	&__item-big {margin-bottom: 16px}
}

@media only screen and (max-width : 768px) {
	.result-search {
		&__view {margin-bottom: 0}
	}
}

@media only screen and (max-width : 576px) {
	.result-search {
		&__view {display: none}
	}
}