.catalog-menu {
	position: absolute;
	top: 59px;
	left: 50%;
	width: 100vw;
	margin-left: -50vw;
	background: $white;
	color: $text-dark;
	border-bottom: 3px solid #efefef;
	font-weight: 400;
	transition: .3s ease-out;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	&__list {
		max-width: 300px;
		width: 100%;
		min-height: 300px;
		background: #f9f9f9;
		li {
			&:hover {
				.catalog-submenu {
					opacity: 1;
					visibility: visible;
					z-index: 5101;
				}
			}
		}
	}
	.container-xl {
		position: relative;
	}
	li {
		height: auto;
		white-space: normal;
		a {
			padding: 8px 20px;
			text-align: left;
		}
	}
	
}
	
.catalog-submenu {
	position: absolute;
	top: 0;
	left: 315px;
	right: 15px;
	color: $black;
	font-size: 12px;
	font-weight: 400;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
	li {
		white-space: normal;
		a {
			&:hover {
				color: $accent;
				text-decoration: underline;
			}
		}
		&:hover {
			background: $white;
			color: $black;
		}
	}
}

@media only screen and (max-width : 1199px) {
	.catalog-menu {
		.container-xl {
			padding: 0;
		}
	}
	.catalog-submenu {
		left: 300px;
		right: 0;
	}
}