// Colors
$accent: #ef8d38;
$accent2: #ffa252;
$accent3: #fcaa63;
$text: #4b535b;
$text-dark: #292e36;
$text-lgh: #6b7278;
$clre0: #e0e0e0;
$clrf9: #f9f9f9;
$clrf3f2f1: #f3f2f1;
$white: #ffffff;
$black: #000000;

// Sizes
$base-font-size: 16px;