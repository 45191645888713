.s-payment-delivery {
	padding: 48px 0;
	background: #fcfcfc;
}

@media only screen and (max-width : 768px) {
	.s-payment-delivery {
		padding: 24px 0;
		background: $white;
	}
}