.bread-crumbs {
	height: auto;
	padding: 24px 0;
	&__list {
		flex-wrap: wrap;
		li {
			margin-right: 24px;
			margin-bottom: 4px;
			&:last-child {
				a {
					color: #a4a4a4;
					cursor: default;
				}
				span::after {display: none}
			}
		}
		span {
			position: relative;
			&::after {
				content: url(../../../../static/images/general/arrow.svg);
				position: absolute;
				right: -18px;
				top: 2px;
			}
		}
	}
	&--bgf9 {background: $clrf9}
}

@media only screen and (max-width : 768px) {
	.bread-crumbs {
		&__list {
			min-width: 100%;
			flex-wrap: nowrap;
			overflow-x: auto;
			overflow-y: hidden;
			li {
				margin-bottom: 0;
				flex-shrink: 0
			}
			&::-webkit-scrollbar {visibility: hidden}
		}
	}
}