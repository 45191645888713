.filters {
	position: relative;
	&__list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
	}
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 4px;
		color: $text-dark;
		border-bottom: 1px solid $clre0;
		font-size: 14px;
		line-height: 150%;
		transition: .3s ease-out;
		cursor: pointer;
		&:hover {
			color: $accent;
			border-bottom-color: $accent;
			svg {stroke: $accent}
		}
		&:active {
			color: $accent2;
			border-bottom-color: $accent2;
			svg {stroke: $accent2}
		}
		&.active {
			color: $accent;
			border-bottom-color: $accent;
			.filters__value {
				display: inline-block;
			}
			.filters__icon {
				svg {
					stroke: $accent;
					transform: rotate(-90deg);
				}
			}
		}
	}
	&__value {
		display: none;
		margin-left: 4px;
		flex-shrink: 0;
	}
	&__icon {
		svg {
			width: 10px;
			height: 16px;
			stroke: $text-dark;
			stroke-width: 2px;
			fill: none;
			transform: rotate(90deg);
			transition: .3s ease-out;
		}
	}
	&__clear {
		color: $accent;
		cursor: pointer;
		svg {
			width: 12px;
			height: 12px;
			stroke: $accent;
			margin-right: 4px;
		}
		&:hover {
			color: $accent2;
			svg {stroke: $accent2;}
		}
		&:active {
			color: $accent3;
			svg {stroke: $accent3;}
		}
	}
	&__panel {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		min-height: 258px;
		padding: 14px 12px 18px;
		background: $white;
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 3px 3px;
		z-index: 100;
		&.open {
			display: block;
		}
	}
}

@media only screen and (max-width : 768px) {
	.filters {
		&__clear {display: none !important}
	}
}