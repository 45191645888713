.p-payment-delivery {padding: 0 0 48px;}

.payment {margin-bottom: 24px;}

.payment, .delivery {
    &__subtitle {margin-bottom: 16px;}
}

@media only screen and (max-width : 768px) {
    .p-payment-delivery {padding: 0 0 24px;}
}