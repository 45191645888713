.category-card {
	position: relative;
	width: 100%;
	height: 200px;
	img {
		width: 100%;
		height: 200px;
	}
	&:hover {
		.btn-arrow {
			background: $accent;
			border-color: $accent;
			svg {stroke: $white;}
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $black;
			opacity: .5;
		}
	}
	&:focus {
		&::after {opacity: .3;}
	}
	&__button {
		position: absolute;
		bottom: 30px;
		left: 18px;
		right: 18px;
		height: 58px;
		border: 1px solid transparent;
		color: $white;
		z-index: 100;
		span {
			flex: 1;
		}
	}
	.btn-arrow {
		background: transparent;
		border: 1px solid $white;
		svg {stroke: $accent;}
	}
}

@media only screen and (max-width : 576px) {
	.category-card {
		height: auto;
		img {display: none;}
		&::after {display: none;}
		&__button {
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			height: auto;
			padding: 4px 14px;
			color: $text;
			border-color: $clre0;
			.btn-arrow {
				border-color: $clre0;
			}
			&:hover {box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);}
		}
	}
}