@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	font-size: $base-font-size;
	height: 100%;
}

body {
	height: 100%;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	color: $text;
	overflow-x: hidden;
}

*,
*::before,
*::after {box-sizing: border-box}

ul[class],
ol[class] {
	list-style: none;
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {margin: 0}

a:not([class]) {text-decoration-skip-ink: auto}

img {
	max-width: 100%;
	display: block;
}

input,
button,
textarea,
select {font: inherit}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {vertical-align: middle}

textarea {resize: none}

section {position: relative}

input,
select,
button {outline: none !important}

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content {flex: 1 0 auto}
	
input, textarea {
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	outline: none;
	resize: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	&:focus:required:invalid {
		border-color: #eb5757;
		transition: .3s ease-out;
	}
	&:required:valid {
		border-color: green;
		transition: .3s ease-out;
	}
	&::placeholder {
		font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
	}
}
a {
	display: inline-block;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

p {
	margin-bottom: 24px;
	&:last-child {margin-bottom: 0}
}

input[type=text] {
	&::-ms-clear, &::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
}
	
input[type="search"] {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {display: none}
}

button {outline: none;}

label {margin: 0}
//Модули
@import "modules/modules";