.filters-panel {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto;
	gap: 8px 10px;
	max-height: 256px;
	overflow-y: auto;
	&::-webkit-scrollbar-track {
		border-radius: 50px;
		background: #f5f5f5;
	}
	&::-webkit-scrollbar {
		width: 6px;
		background: #f5f5f5;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 50px;
		background: $accent;
	}
}