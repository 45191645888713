.s-about {
	padding: 48px 0;
	background: #f8f8f8;
	color: #8e97a2;
}

@media only screen and (max-width : 768px) {
	.s-about {
		padding: 24px 0;
	}
}