.specifications {
	&__title {color: $text-dark;}
}


.table {
	table {
		width: 100%;
		color: $text-dark;
	}
	tr {
		&:nth-child(odd) {
			background: #f8f8f8;
		}
	}
	td {
		padding: 15px;
		border: none;
		&:first-child {
			width: 65%;
			padding-left: 75px;
		}
		&:last-child {padding-right: 75px}
	}
}

@media only screen and (max-width : 1199px) {
	.specifications {
		&__table {padding: 0;}
	}
}

@media only screen and (max-width : 1023px) {
	.table {
		td {
			&:first-child {padding-left: 15px}
			&:last-child {padding-right: 15px}
		}
	}
}

@media only screen and (max-width : 576px) {
	.table {
		tr {
			display: flex;
			flex-direction: column;
			padding: 12px 15px;
		}
		td {
			padding: 0;
			&:first-child {
				width: 100%;
				padding: 0;
				font-weight: 300;
			}
			&:last-child {
				padding: 0;
			}
		}
	}
}