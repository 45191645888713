.spacial-offer {
	&__item {
		width: 100%;
		margin: 0 auto;
		img {
			width: 100%;
			height: 200px;
		}
		&:hover {
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
			.offer-content__title {color: $accent}
		}
	}
}

.offer-content {
	padding: 16px 12px;
	&__title {
		color: $text-dark;
		font-size: 20px;
		font-weight: 400;
		&:hover {text-decoration: underline}
	}
	&__subtitle {
		margin-bottom: 16px;
		font-size: 14px;
		font-weight: 300;
	}
	&__subtitle, &__buttons {margin-top: 16px}
	&__in-basket, &__phone {
		position: relative;
		z-index: 100;
	}
	&__phone {
		margin-left: 8px;
		flex-shrink: 0;
		.btn-txt {
			display: none;
			cursor: pointer;
		}
		.btn-svg {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			border: 2px solid $accent;
			text-align: center;
			cursor: pointer;
			svg {
				width: 19px;
				height: 19px;
			}
			&:hover {
				border-color: $accent2;
				svg {
					fill: $accent2;
					stroke: $accent2;
				}
			}
		}
	}
}

@media only screen and (max-width : 576px) {
    .spacial-offer {
		&__item {
			max-width: 100%;
			margin: 0;
			background: $white;
			img {display: none !important}
		}
	}
	.offer-content {
		&__in-basket {max-width: 124px}
		&__phone {
			.btn-txt {display: inline-block}
			.btn-svg {display: none}
		}
	}
}