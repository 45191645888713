.slider-two {
	.owl-prev {right: 95px}
	.owl-next {right: 0}
}

@media only screen and (max-width : 576px) {
	.slider-two {
		.owl-nav {
			position: relative;
			width: 100%;
		}
		.owl-prev {
			margin-left: auto;
			margin-right: 46px;
			right: 0;
		}
		.owl-dots {margin-top: 10px}
	}
}