.slider-four {
	img {
		width: 100%;
		height: 100%;
	}
	.owl-item {
		height: 350px;
	}
	.owl-dots {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
	}
	.owl-dot {
		width: 65px;
		height: 65px;
		background: none;
		border: 1px solid transparent !important;
		transition: .3s ease-out;
		cursor: pointer;
		&:hover {border-color: $clre0 !important}
		&:last-child {margin-bottom: 0}
		img {
			width: 100%;
			height: 100%;
		}
		&.active {border-color: $clre0 !important}	
	}
}

@media only screen and (max-width : 576px) {
	.slider-four {
		.owl-item, img {height: 290px}
		.owl-dots {justify-content: center;}
		.owl-dot {
			width: 20px;
			height: 20px;
			background: $white;
			border: 1px solid $accent !important;
			border-radius: 3px;
			outline: none;
			cursor: pointer;
			&:not(:last-child) {margin-right: 8px;}
			img {display: none}
		}
		.owl-dot.active {background: $accent}
	}
}