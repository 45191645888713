.btn {
	display: inline-block;
	position: relative;
	width: 100%;
	height: 54px;
	padding: 18px 10px 0;
	background: $accent;
	color: $white;
	border: 2px solid transparent;
	border-radius: 3px;
	font-size: 16px;
	text-align: center;
	line-height: 1;
	transition: .3s ease-out;
	cursor: pointer;
	&:hover {
		background: $accent2;
		color: $white;
	}
	&--white {
		background: $white;
		color: $accent;
		border-color: $accent;
		&:hover {
			background: $white;
			color: $accent2;
			border-color: $accent2;
		}	
	}
	&--wdh224 {max-width: 224px;}
	&--wdh180 {
		max-width: 180px;
		height: 40px;
		padding: 10px 10px 0;
	}
	&--brad {border-radius: 50px;}
}

.btn-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	background: $accent;
	border: 2px solid transparent;
	border-radius: 3px;
	transition: .3s ease-out;
	svg {
		width: 10px;
		height: 18px;
		stroke: $white;
		stroke-width: 2px;
		fill: none;
	}
	&:hover {
		background: $accent2;
	}
	&--white {
		background: $white;
		border-color: $accent;
		svg {stroke: $accent;}
		&:hover {
			border-color: $accent2;
			svg {stroke: $accent2;}
		}
	}
}

.btn-dwl {
	position: relative;
	max-width: 240px;
	width: 100%;
	height: 44px;
	padding: 10px 15px;
	background: $text;
	color: $white;
	border-radius: 3px;
	transition: .3s ease-out;
	flex-shrink: 0;
	&:hover {
		background: $text-lgh;
		color: $white;
	}	
	&--crcl {
		max-width: 44px;
		border-radius: 50%;
		padding: 10px;
	}
}

.btn-tab {
	position: relative;
	width: 100%;
	height: 48px;
	background: $white;
	border-radius: 50px;
	border: 2px solid $clre0;
	text-align: left;
	transition: .3s ease-out;
	cursor: pointer;
	flex-shrink: 0;
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -2px;
		right: -2px;
		width: 48px;
		height: 48px;
		border: 2px solid $accent;
		border-radius: 50%;
		transition: .3s ease-out;
	}
	svg {
		position: relative;
		width: 11px;
		height: 18px;
		fill: none;
		stroke: $accent;
		stroke-width: 2px;
		transition: .3s ease-out;
	}		
	&::after {
		position: absolute;
		content: 'Следующий этап';
		top: 10px;
		left: 26px;
		color: $accent;
		transition: .3s ease-out;
	}
	&:hover {
		background: $white;
		span {
			background: $accent2;
			border-color: $accent2;
		}	
		svg {stroke: $white}
		&::after {color: $accent2}
	}
	&--wdh224 {max-width: 224px}
	&--prev {
		color: $clre0;
		text-align: right;
		span {
			top: 0;
			left: 0;
			height: 44px;
			border: none;
		}
		svg {
			stroke: $clre0;
			transform: rotate(180deg);
		}
		&::after {
			content: 'Предыдущий этап';
			right: 26px;
			color: $clre0;
		}
		&:hover {
			color: $accent2;
			border-color: $accent2;
			span {background: $white;}
			svg {stroke: $accent2}
		}
	}
}

.btn-tabs-top {
	display: flex;
	align-items: center;
	color: $accent;
	svg {
		margin-left: 16px;
		width: 7px;
		height: 14px;
		stroke-width: 3px;
		stroke: $accent;
		fill: none;
	}
	&--prev {
		color: $clre0;
		svg {
			margin-left: 0;
			margin-right: 16px;
			stroke: $clre0;
			transform: rotate(180deg);
		}
	}
	&:hover {
		color: $accent2;
		svg {stroke: $accent2}
	}
	&:active {
		color: $accent3;
		svg {stroke: $accent3}
	}
}

@media only screen and (max-width : 576px) {
	.btn-tab {
		background: $accent;
		border-color: $accent;
		span {
			svg {stroke: $white}
		}
		&::after {
			content: 'Далее';
			left: 30px;
			right: 46px;
			text-align: center;
			color: $white;
		}
		&:hover {
			background: $accent2;
			border-color: $accent2;
			span {
				background: $accent2;
				border-color: $accent2
			}
			svg {stroke: $white}
				
			&::after {color: $white}
				
		}
		&--wdh224 {max-width: 126px}
		&--prev {
			background: $white;
			border-color: $clre0;
			margin-right: 8px;
			padding-right: 0;
			span {
				svg {stroke: $clre0}
			}
			&::after {
				content: 'Назад';
				right: 30px;
				left: 46px;
				color: $clre0;
			}
		}
	}
}