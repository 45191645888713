.slider-one {
	.owl-prev, .owl-next {
		top: 164px;
		width: 72px;
		height: 72px;
	}
	.owl-prev {left: 0;}	
	.owl-next {right: 0;}
}

.slider {
	max-width: 100%;
	position: relative;
	height: 400px;
	padding: 0 105px;
	&__item {
		padding: 38px 0
	}
	&__content {
		width: 330px;
		margin-right: 30px;
	}
	&__title {
		margin-bottom: 13px;
	}
	&__btn-buy {
		display: block;
		margin-top: 24px;
	}
	&__content-image {
		flex: 1 1 480px;
		max-width: 480px;
		img {
			width: 100%;
			height: auto;
		}
	}
}

@media only screen and (max-width : 768px) {
	.slider-one {
		.owl-prev, .owl-next {
			top: 0;
			width: 40px;
			height: 40px;
			background: $white !important;
		}
		.owl-nav {
			position: absolute;
			bottom: 24px;
			right: 0;
			width: 86px;
			height: 40px;
			background: $clrf9;
		}
		.owl-prev {
			margin-left: auto;
			margin-right: 46px;
			right: 0;
		}
	}
		
	.s-slider {background: $clrf9;}
	.slider {
		height: auto;
		padding: 0;
		&__content {
			width: 100%;
			margin: 0;
		}
		&__title {font-size: 24px;}
		&__subtitle{font-size: 14px;}
		&__content-image {display: none;}
		&__item {padding: 24px 0;}
	}
}