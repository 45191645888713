.owl-carousel {
	.owl-prev, .owl-next {
		display: inline-block;
		position: absolute;
		width: 65px;
		height: 65px;
		background: $white;
		border: 1px solid $clre0 !important;
		border-radius: 3px;
		transition: .3s ease-out;
		outline: none;
		&:hover {
			background: $accent !important;
			svg {stroke: $white;}	
		}
	}
	svg {
		stroke: $accent;
		transition: .3s ease-out;
	}
}

@media only screen and (max-width : 576px) {
	.owl-carousel {
		.owl-prev, .owl-next {
			top: 0;
			width: 40px;
			height: 40px;
		}
	}
	.slider-two, .slider-three {
		.owl-dot {
			width: 20px;
			height: 20px;
			margin-right: 8px;
			background: $white;
			border: 1px solid $accent !important;
			border-radius: 3px;
			outline: none;
			cursor: pointer;
			&.active {background: $accent}
		}
	}
}