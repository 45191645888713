.s-spacial-offer {
	position: relative;
	padding: 48px 0;
}

@media only screen and (max-width : 768px) {
	.s-spacial-offer {padding: 24px 0;}
}

@media only screen and (max-width : 576px) {
	.s-spacial-offer {background: #fcfcfc;}
}