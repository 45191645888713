.product-card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 4px;
	border-bottom: 1px solid $clre0;
	&__image {
		width: 66px;
		height: 58px;
		margin-right: 12px;
		flex-shrink: 0;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		margin-right: 30px;
		font-size: 14px;
		font-weight: 500;
		line-height: 150%;
		&:hover {
			text-decoration: underline;
		}
	}
	&__price {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		margin-right: 50px;
		text-align: right;
	}
	&__buttons {
		display: flex;
		flex: 1 0 152px;
	}
	&__phone {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		max-width: 30px;
		height: 30px;
		margin-right: 12px;
		padding: 6px;
		svg {
			width: 11px;
			height: 15px;
		}
		span {
			display: none;
		}
	}
	&__basket {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 110px;
		height: 30px;
		padding: 4px 25px;
		font-size: 12px;
		line-height: 14px;
		flex-shrink: 0;
	}
	.old-price {
		font-size: 14px;
		font-weight: 500;
		line-height: 125%;
		span {
			font-size: 12px;
			font-weight: 400;
		}
	}
	.new-price {
		font-size: 18px;
		font-weight: 700;
		line-height: 125%;
		span {
			font-size: 14px;
		}
	}
	&:hover {
		.product-card__title {
			color: $accent;
		}
	}
}

@media only screen and (max-width : 991px) {
	.product-card {
		&__title {
			margin-right: 12px;
		}
		&__price {
			margin-right: 12px;
		}
	}
}

@media only screen and (max-width : 576px) {
	.product-card {
		flex-direction: column;
		align-items: flex-start;
		padding: 12px 10px;
		border: 1px solid $clre0;
		border-radius: 3px;
		&__image {display: none}
		&__title {
			margin: 0 0 12px;
			font-size: 16px;
			font-weight: 400;
		}
		&__price {
			margin: 0 0 20px;
			text-align: left;
		}
		&__buttons {
			flex: 1;
			flex-direction: column;
			width: 100%;
		}
		&__phone {
			max-width: 290px;
			height: 40px;
			margin: 0 0 8px;
			padding: 10 30px;
			border-radius: 3px;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			svg {display: none}
			span {display: block}
		}
		&__basket {
			max-width: 290px;
			height: 40px;
			padding: 10px 30px;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
		}
		.old-price {
			font-size: 16px;
			line-height: 150%;
			span {
				font-size: 16px;
			}
		}
		.new-price {
			font-size: 24px;
			line-height: 125%;
			span {
				font-size: 24px;
				font-weight: 400;
			}
		}
	}
}