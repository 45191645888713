.s-404 {
	padding: 48px 0;
	text-align: center;
}

.not-found {
	&__image {
		display: block;
		max-width: 365px;
		height: auto;
		margin: 0 auto 16px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__title, &__text {
		max-width: 640px;
		width: 100%;
		margin: 0 auto;
		color: #4f4f4f;
	}
	&__title {
		margin-bottom: 16px;
		font-size: 28px;
		line-height: 38px;
		font-weight: 600;
	}
	&__text {
		margin-bottom: 48px;
		font-size: 18px;
		line-height: 25px;
	}		
	&__button {
		max-width: 255px;
		height: 40px;
		padding-top: 10px;
	}
}

@media only screen and (max-width : 768px) {
	.s-404 {padding: 24px 0}
}

@media only screen and (max-width : 576px) {
	.not-found {
		&__title {
			font-size: 18px;
			line-height: 25px;
		}
		&__text {
			margin-bottom: 24px;
			font-size: 14px;
			line-height: 19px;
		}
	}
}