.questionnaire-nav-panel {
	border: none;
	&__item {
		color: #c1c1c1;
		&:not(:last-child) {
			margin-right: 30px;
		}
	}
	.nav-link {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 116px;
		height: 116px;
		padding: 16px 6px;
		background: $white;
		border: none;
		border-radius: 15px;
		transition: .3s ease-out;
		svg {
			margin-bottom: 14px;
			transition: .3s ease-out;
		}
		&.active {
			color: $accent;
			svg {fill: $accent}
		}
		&:hover {
			color: $accent;
			svg {fill: $accent}
		}
		&:active {
			color: $accent2;
			svg {fill: $accent2}
		}
	}
}

@media only screen and (max-width : 1024px) {
	.questionnaire-nav-panel {
		&__item {
			&:not(:last-child) {margin-right: 15px}
		}
		.nav-link {width: 106px}
	}
}