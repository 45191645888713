.product-card-big {
	display: flex;
	justify-content: space-between;
	padding: 0 12px;
	border: 1px solid $clre0;
	&__left {
		display: flex;
		justify-content: space-between;
	}
	&__content {
		max-width: 256px;
		width: 100%;
		margin-right: 30px;
		padding: 16px 0;
	}
	&__image {
		height: 150px;
		margin-bottom: 14px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		color: $text-dark;
		font-size: 14px;
		font-weight: 500;
		line-height: 150%;
	}
	&__center {
		padding: 16px 0;
	}
	&__right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-shrink: 0;
		max-width: 184px;
		width: 100%;
		margin-left: 30px;
		padding: 16px 0 16px 12px;
		border-left: 1px solid $clre0;
		.learn-price {justify-content: flex-end}
	}
	&__article-card {
		margin-bottom: 24px;
		color: $text;
		font-size: 14px;
		line-height: 150%;
	}
	&__price {
		text-align: right;
	}
	&__phone, &__basket {
		height: 40px;
		padding: 8px;
		line-height: 19px;
	}
	&__phone {margin-bottom: 12px}
	.old-price {
		font-size: 14px;
		font-weight: 500;
		line-height: 125%;
		span {
			font-size: 12px;
			font-weight: 400;
		}
	}
	.new-price {
		font-size: 18px;
		font-weight: 700;
		line-height: 125%;
		span {
			font-size: 14px;
		}
	}
}

.specifications {
	&__list {
		font-size: 12px;
		line-height: 150%;
		li {
			span {
				&:last-child {
					margin-left: 4px;
					font-weight: 700;
					line-height: 125%;
				}
			}
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
}

@media only screen and (max-width : 992px) {
	.product-card-big {
		&__left {
			flex-direction: column;
			width: 100%;
		}
		&__content {
			max-width: 100%;
			margin-right: 0;
		}
		&__image {display: none}
		&__center {
			padding: 0 0 16px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.product-card-big {
		&__left {
			flex-direction: row;
			margin-right: 15px;
		}
		&__content {
			max-width: 256px;
			margin-right: 30px;
		}
		&__image {display: block}
		&__center {padding: 16px 0;
		}
		&__right {
			margin-left: 15px;
		}
	}
}

@media only screen and (max-width : 660px) {
	.product-card-big {
		&__left {
			flex-direction: column;
			width: 100%;
		}
		&__content {
			max-width: 100%;
			margin-right: 0;
		}
		&__image {display: none}
		&__center {
			padding: 0 0 16px;
		}
	}
}

@media only screen and (max-width : 576px) {
	.product-card-big {display: none}
}