.item-card {
	position: relative;
	padding: 8px 0;
	&__close {
		position: absolute;
		top: 8px;
		right: 0;
		cursor: pointer;
		&:hover {
			svg {stroke: $accent}
		}
		&:active {
			svg {stroke: $accent2}
		}
	}
	&__title {
		width: 100%;
		margin-bottom: 4px;
		padding-right: 30px;
		&:hover {color: $accent2}
		&:active {color: $accent3}
	}
	&__subtitle {
		margin-bottom: 8px;
	}
}