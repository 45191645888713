.basket-price {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 26px;
		background: #f8f8f8;
		&:first-child {border-bottom: 1px solid $clre0}
		&:last-child {background: $clrf3f2f1}
	}
}

@media only screen and (max-width : 576px) {
	.basket-price {
		&__item {padding: 16px 14px}
	}
}