.not-result-search {
	&__title {color: $text-dark}
	&__buttons {margin-top: 40px;}
	&__button {
		&:not(:last-child) {
			margin-right: 40px;
		}
	}
}

@media only screen and (max-width : 576px) {
	.not-result-search {
		&__title {color: $accent}
		&__buttons {margin-top: 24px}
		&__button {
			&:not(:last-child) {
				margin-bottom: 16px;
				margin-right: 0;
			}
		}
	}
}