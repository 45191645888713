header {
	flex: 0 0 auto;
}

.header {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 700;
	&__top-line {
		height: 40px;
		background: $text;
		color: $white;
	}
	&__middle-line {
		height: 85px;
		font-size: 18px;
	}
	&__bottom-line {
		height: 60px;
		border-top: 1px solid $clre0;
		border-bottom: 1px solid $clre0;
	}
}

.top-line {
	width: 100%;
	&__mail, &__time {
		display: flex;
		align-items: center;
		svg {
			margin-right: 8px;
		}
	}
	&__buttons, &__menu {display: none !important;}

	&__search, &__phone, &__basket {
		width: 62px;
		height: 62px;
		background: $text;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	&__basket {
		background: $accent;
	}
	&__menu {
		width: 112px;
		height: 62px;
		padding: 24px 15px;
		background: #696f76;
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
		svg {
			margin-right: 12px;
		}
	}
}

.middle-line {
	width: 100%;
	&__logo {
		flex-shrink: 0;
		line-height: 1;
	}
	&__city {
		flex-shrink: 0;
		margin: 0 15px;
		color: $text;
		font-size: 14px;
		font-weight: 700;
		cursor: pointer;
		svg {
			margin-right: 8px;
		}
	}
	&__search {
		flex: 1;
		form {
			position: relative;
		}
		.icon-search {
			width: 18px;
			height: 18px;
			fill: #b8b8b8;
		}
	}
	&__phone {
		flex-shrink: 0;
		margin-left: 18px;
		color: $text;
		text-align: right;
	}
	&__callback {
		margin-top: 8px;
		color: $accent !important;
		font-weight: 700;
		cursor: pointer;
		&:hover {
			color: $accent2 !important;
			svg {
				fill: $accent2;
			}
		}
		svg {
			width: 12px;
			height: 16px;
			margin-right: 4px;
		}
	}
	.input-srch {
		padding-right: 55px
	}
	.input-rst {
		position: absolute;
		top: 17px;
		right: 20px;
		padding: 0;
		background: 0;
		border: none;
		line-height: 1;
	}
	.icon-close {display: none}
	.input-srch:focus + button .icon-close {
		display: block;
	}
	.input-srch:focus + button .icon-search {
		display: none;
	}
}

.bottom-line {
	position: relative;
	width: 100%;
	&__navigator-panel {
		flex: 1;
		li {
			width: 100%;
			height: 58px;
			text-align: center;
			white-space: nowrap;
			&:hover {
				background: $accent;
				color: $white;
			}
		}
		a {
			width: 100%;
			padding: 17px 20px;
			cursor: pointer;
		}
	}
	&__basket {
		flex-shrink: 0;
		margin-left: 42px;
		&:hover {
			.basket__basket-list {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.catalog-nav {
	background: $accent;
	color: $white;
	svg {
		width: 20px;
		margin-right: 16px;
	}
	&:hover {
		background: $accent2 !important;
		.catalog-menu {
			opacity: 1;
			visibility: visible;
			z-index: 5100;
		}
	}
}

.basket {
	position: relative;
	width: 110px;
	height: 46px;
	background: $accent;
	color: $white;
	border-radius: 55px;
	font-size: 16px;
	transition: .3s ease-out;
	cursor: pointer;
	z-index: 200;
	&__basket-list {
		position: absolute;
		top: 52px;
		right: 0;
		width: 350px;
		padding: 30px 0 0;
		color: $text;
		transition: .3s ease-out;
		z-index: 100;
		opacity: 0;
		visibility: hidden;
	}
	svg {
		width: 20px;
		height: 15px;
		margin-right: 8px;
	}
	&:hover {
		background: $accent2;
		color: $white;
	}
}

@media only screen and (max-width : 1199px) {
	.bottom-line {
		padding: 0;
	}
}

@media only screen and (max-width : 1060px) {
	.bottom-line {
		&__navigator-panel {
			a {
				padding: 17px 4px;
			}
		}
		&__basket {
			margin-left: 10px;
		}
	}
	.catalog-nav {
		a {
			padding: 17px 10px 17px;
		}
	}
	.basket {
		width: 90px;
	}
}

@media only screen and (max-width : 900px) {
	.bottom-line {
		&__basket {
			margin-left: 4px;
		}
	}
	.catalog-nav {
		a {
			padding: 17px 4px 17px;
		}
		svg {
			margin-right: 4px;
		}
	}
	.basket {
		width: 70px;
		svg {
			margin-right: 4px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.header {
		&__top-line {
			height: 62px;
			.container-xl {
				padding: 0;
			}
		}
		&__middle-line {
			height: 46px;
		}
		&__bottom-line {
			display: none;
		}
	}
	.top-line {
		&__mail, &__time {
			display: none;
		}
		&__buttons, &__menu {display: flex !important;}
	}
	.middle-line {
		&__city, &__search, &__callback  {
			display: none !important;
		}
		&__logo {
			width: 104px;
		}
		&__phone {
			color: $accent;
			a {
				text-decoration: underline;
			}
		}
	}
}