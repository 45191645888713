/* Мобильное меню */
.mobile-menu {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;
	&.loaded {display: block}
	&__menu {
		position: absolute;
		top: 0;
		bottom: 0;
		max-width: 500px;
		width: 100%;
		min-height: 100%;
		background: $text;
		color: $white;
		transform: translateX(-600px);
		transition: .3s ease-out;
		z-index: 920;
	}
	&__overlay {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .4);
		cursor: pointer;
	}
}

.header-panel {
	padding: 24px 16px;
	&__button {
		position: relative;
		cursor: pointer;
	}
	&__sity {
		font-size: 14px;
		cursor: pointer;
		svg {
			margin-left: 8px;
		}
	}
}

a.menu__parent.catalog {background: $accent}

.menu {
	overflow: auto;
	&__list {
		margin-bottom: 32px;
		ul {
			background: $text;
			transition: .3s ease-out;
			overflow: auto;
			ul {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				min-height: 100%;
				transform: translateX(-600px);
				z-index: 915;
			}
			ul.loaded {transform: translateX(0)}
		}
		li {
			color: $white;
			font-size: 18px;
			font-weight: 300;
			line-height: 125%;
			transition: .3s ease-out;
			&:hover {background: $accent}
			&:active {background: $accent}
		}
		a {
			position: relative;
			width: 100%;
			padding: 12px 16px;
			padding-right: 50px;
			&.menu__parent, &.menu__back {
				&::after {
					content: url(../../../../static/images/general/arrow-wht.svg);
					position: absolute;
					top: 50%;
					height: 24px;
					transform: translateY(-50%);
				}
			}
			&.menu__parent {
				&::after {right: 16px}
			}
			&.menu__back {
				padding: 20px 16px 20px 48px;
				border-bottom: 2px solid $text-lgh;
				&::after {
					left: 16px;
					height: 12px;
					transform: rotate(180deg);
				}
			}
		}
	}
	&__basket {
		display: inline-block;
		margin-left: 16px;
		margin-bottom: 32px;
		background: $accent;
		border-radius: 55px;
		line-height: 19px;
		a {
			height: 45px;
			padding: 12px 18px;
		}
		svg {
			width: 19px;
			height: 15px;
			margin-right: 8px;
			line-height: 19px;
		}
		&:active {background: $accent2}
	}
	&__time, &__mail {
		margin-left: 16px;
		svg {margin-right: 8px}
	}
	&__time {
		margin-bottom: 22px
	}
	&.loaded {
		transform: translateX(0);
		.header-panel__button {
			svg {
				transform: rotate(90deg);
				transition: .7s ease-out;
			}
		}
	}
}