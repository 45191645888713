.p-navigator {
	margin-top: 32px;
	&__list {
		li {
			width: 40px;
			height: 40px;
			border: 2px solid $accent;
			border-radius: 3px;
			text-align: center;
			line-height: 40px;
			transition: .3s ease-out;
			&:hover {
				background: $accent;
				a {color: $white}
				svg {stroke: $white}
			}
			&:active {
				background: $accent2;
				border-color: $accent2;
			}
			&.active {
				background: $accent;
				a {color: $white}
				svg {stroke: $white}
			}
			&:not(:last-child){margin-right: 8px}
		}
		a {
			width: 100%;
			height: 100%;
		}
	}
	&__prev, &__next {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		svg {
			fill: none;
			width: 9px;
			height: 16px;
			stroke-width: 2px;
			stroke: $accent;
		}
	}
	&__prev {
		svg {transform: rotate(180deg)}
	}
}