.s-news-inner {padding: 0 0 48px;}

.news-inner {
	&__text {
		img {
			float: left;
			clear: both;
			max-width: 400px;
			width: 100%;
			height: 275px;
			margin-right: 24px;
			margin-bottom: 24px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.s-news-inner {padding: 0 0 24px;}

	.news-inner {
		&__text {
			img {
				max-width: 300px;
				height: 205px;
			}
		}
	}
}

@media only screen and (max-width : 576px) {
	.news-inner {
		&__text {
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}