.basket-list {
	position: relative;
	padding: 12px 12px 20px;
	background: $white;
	border: 5px;
	box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
	&__list {
		margin-bottom: 16px !important;
		li {
			border-bottom: 1px solid $clre0;
		}
	}
	&__basket {
		display: block;
		margin: 0 auto 14px;
	}
	&__click {
		text-align: center;
	}
	&::after {
		content: '';
		position: absolute;
		top: -46px;
		right: 26px;
		border: 23px solid transparent;
		border-bottom: 23px solid $white;
	}
}