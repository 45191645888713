.s-certificates {
	padding: 48px 0;
	.owl-nav {display: none}
}

.certificates {
	&__item {
		display: block;
		max-width: 255px;
		height: 360px;
		margin: 0 auto 10px;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

@media only screen and (max-width : 768px) {
	.s-certificates {
		padding: 24px 0 48px;
	}
}

@media only screen and (max-width : 576px) {
	.s-certificates {
		.owl-nav {display: block}
	}
}