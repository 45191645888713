.view {
	color: $text-dark;
	&__display {
		.icon-view {
			margin: 0 12px;
		}
	}
	svg {
		cursor: pointer;
		&:hover {fill: $accent}
		&:active {fill: $accent2}
	}
}

@media only screen and (max-width : 768px) {
	.view {
		&__display {
			position: absolute;
			top: 0;
			right: 15px;
		}
	}
}