.submenu-result-search {
	.submenu {
		&__title {
			border: none;
			color: $text-dark;
			&:last-child {
				border-bottom: none;
			}
			a {
				&:hover {
					background: $white;
					color: $accent;
				}
				&:active {color: $accent2}
			}
		}
	}
	.accordion {
		&.active {
			background: $white;
			color: $accent;
			border-bottom: none;
			&::after {
				content: url(../../../../static/images/general/arrow-acc.svg);
			}
			&:hover {
				background: $white;
				color: $accent2;
			}
			&:active {
				background: $white;
				color: $accent3;
			}
		}
		&:hover {
			&::after {
				content: url(../../../../static/images/general/arrow-acc.svg);
			}
		}
	}
}

.result-search-value {
	.accordion {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 0 !important;
		&::before, &::after {
			display: none;
		}
	}
}

.search-count {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	min-width: 32px;
	height: 32px;
	margin-left: 10px;
	padding: 5px;
	border: 1px solid $clre0;
	border-radius: 55px;
}

.submenu {
	a {
		width: 100%;
	}
	&__title {
		border: 1px solid $clre0;
		border-bottom: none;
		color: $accent;
		font-size: 13px;
		line-height: 150%;
		font-weight: 700;
		&:first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		&:last-child {
			border-bottom: 1px solid $clre0;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		a {
			&:hover {
				background: $accent;
				color: $white;
			}
			&:active {
				background: $accent2;
			}
		}
		
	}
	&__list {
		display: none;
		color: $text;
		li {
			&:hover {
				background: $accent;
				color: $white;
			}
		}
	}
	li {
		a {
			padding: 12px 10px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.submenu-hidden {display: none}
	.submenu-result-search {
		display: none;
		.submenu {
			&__title {
				color: $text-lgh;
				font-weight: 400;
				a {
					&:hover {
						background: #f3f2f1;
						color: $text-lgh;
					}
					&:active {color: $text-lgh}
				}
			}
			li {
				a {padding: 12px 20px}
			}
		}
	}
	.result-search-value {
		.accordion {
			padding-right: 12px !important;
		}
	}
}