.tab-pane {
	&__top-buttons {
		display: none;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 8px;
		padding: 10px 12px;
		background: $white;
		border-radius: 5px;
	}
	&__content {
		position: relative;
		padding: 32px 36px 48px;
		background: $white;
		border-radius: 15px;
	}
	&__s-title {
		color: $text-dark;
		margin-bottom: 24px;
	}
	&__s-subtitle {margin-bottom: 48px}
	&__dwl-list {
		position: absolute;
		top: 40px;
		right: 36px;
		svg {margin-right: 15px}
		&:hover {
			svg {fill: $accent2}
		}
		&:active {
			color: $accent3;
			svg {fill: $accent3}
		}
	}
	&__input-radio, &__checkbox, &__checkbox-list, &__line-list {margin-bottom: 24px}
	&__line-list {
		display: flex;
	}
	&__buttons {
		display: flex;
		margin-top: 72px;
		.btn-tab--prev {margin-right: 24px}
	}
	.input-srch {max-width: 320px}
	.textarea {
		display: block;
		max-width: 640px;
	}
}

.input-radio {
	&__radiobox-list {
		display: flex;
	}
}

.radiobox-list {
	margin-left: 12px;
	.radiobox {
		&:not(:last-child) {margin-right: 8px}
	}
}

.contact-info-tab {
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, 320px));
	gap: 24px 80px;
}

.checkbox-list {
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, 320px));
	gap: 12px 0;
}

.check-list {
	&__title {
		font-size: 14px;
		line-height: 150%;
		font-weight: 300;
	}
	&__text {
		color: $text-dark;
		line-height: 150%;
	}
	li {
		&:not(:last-child) {margin-bottom: 24px}
	}
	&__buttons {
		display: flex;
		align-items: center;
		margin-top: 24px;
	}
	&__button {
		flex-shrink: 0;
		max-width: 246px;
		margin-right: 24px;
		padding: 10px;
	}
	&__policy {margin-top: 16px}
}

@media only screen and (max-width : 768px) {
	.tab-pane {
		&__top-buttons {display: flex}
		&__dwl-list {
			position: relative;
			top: 0;
			right: 0;
			margin-top: 16px;
		}
	}
	.contact-info-tab {
		gap: 24px 40px;
	}
	.input-radio {
		&__radiobox-list {
			flex-direction: column;
		}
	}
	.radiobox-list {
		margin-top: 24px;
		margin-left: 0;
	}
}

@media only screen and (max-width : 576px) {
	.tab-pane {
		&__content {
			padding: 26px 14px 36px;
		}
		&__line-list {
			flex-direction: column;
		}
		&__buttons {
			.btn-tab--prev {margin-right: 8px}
		}
		.input-srch {display: block}
	}
	.contact-info-tab {
		grid-template-columns: 1fr;
		gap: 24px 0;
	}
	.checkbox-list {
		grid-template-columns: 1fr;
	}
	.check-list {
		&__buttons {
			flex-direction: column;
			align-items: start;
			margin-top: 24px;
		}
		&__button {
			max-width: 260px;
			margin-right: 0;
		}
		.btn-tab {
			max-width: 260px;
			margin-top: 16px;
			margin-right: 0;
		}
	}
}