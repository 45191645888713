.s-product-list {padding: 0 0 48px}

.product-list {
	&__filters {margin-bottom: 20px}
	&__view {margin-bottom: 32px}
	&__item-big {margin-bottom: 16px}
	&__m-buttons {display: none}
	&__m-submenu-category {
		height: 40px;
		padding: 12px 34px 12px 20px;
		background: $clrf9;
		color: $accent;
		border-radius: 3px;
		font-weight: 500;
		line-height: 16px;
		transition: .3s ease-out;
		cursor: pointer;
		svg {
			width: 16px;
			height: 16px;
			margin-right: 8px;
			fill: $accent;
			stroke-width: 3px;
			transition: .3s ease-out;
		}
		&:hover {
			color: $accent2;
			svg {fill: $accent2}
		}
		&:active {
			color: $accent3;
			svg {fill: $accent3}
		}
	}
	&__m-filters {
		height: 40px;
		padding: 12px 30px;
		background: $accent;
		color: $white;
		border-radius: 55px;
		line-height: 150%;
		transition: .3s ease-out;
		cursor: pointer;
		svg {
			margin-left: 18px;
			stroke-width: 3px;
			transition: .3s ease-out;
		}
		&:hover {background: $accent2}
		&:active {background: $accent3}
	}
	.col-12, .col-lg-9, .col-md-8 {position: static !important}
}

@media only screen and (max-width : 768px) {
	.s-product-list {padding: 0 0 24px}
	.product-list {
		&__s-title {width: 64%}
		&__m-buttons {display: flex}
		&__filters {display: none}
		&__view {margin-bottom: 0}
	}
}

@media only screen and (max-width : 576px) {
	.product-list {
		&__s-title {width: 100%}
		&__view {display: none}
		&__item {margin-bottom: 16px}
		&__m-buttons {flex-direction: column}
		&__m-submenu-category {
			width: 100%;
			margin-bottom: 16px;
		}
		&__m-filters {width: 100%;}
	}
}