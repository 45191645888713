.s-questionnaire {
	padding: 0 0 48px;
	background: #f9f9f9;
}

.questionnaire {
	&__questionnaire-nav-panel {
		padding: 0 100px;
	}
}

@media only screen and (max-width : 1140px) {
	.questionnaire {
		&__questionnaire-nav-panel {
			padding: 0 60px;
		}
	}
}

@media only screen and (max-width : 1024px) {
	.questionnaire {
		&__questionnaire-nav-panel {padding: 0}
	}
}

@media only screen and (max-width : 768px) {
	.questionnaire {
		&__questionnaire-nav-panel {display: none}
	}
}