.basket-card{
	width: 100%;
	padding: 8px 12px 16px;
	border: 1px solid $clre0;
	border-radius: 3px;
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
	}
	&__image {
		flex-shrink: 0;
		width: 54px;
		height: 54px;
		margin-right: 8px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	&__title {
		margin-right: 20px;
		flex: 1;
	}
	&__close {
		flex-shrink: 0;
		height: 20px;
		line-height: 20px;
		cursor: pointer;
		svg {
			width: 20px;
			height: 20px;
			stroke: $accent;
		}
		&:hover {
			svg {stroke: $accent2}
		}
		&:active {
			svg {stroke: $accent2}
		}
	}
	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__price {
		text-align: right;	
	}
	.old-price {
		font-size: 14px;
		font-weight: 500;
		line-height: 125%;
		span {
			font-size: 12px;
			font-weight: 400;
		}
	}
	.new-price {
		font-size: 18px;
		font-weight: 700;
		line-height: 125%;
		span {
			font-size: 14px;
		}
	}
}

@media only screen and (max-width : 576px) {
	.basket-card{
		padding: 8px 12px 12px;
		&__image, &__article-card {
			display: none;
		}
	}
}