.accordion {
	position: relative;
	padding-right: 50px !important;
	&.active {
		background: $accent;
		color: $white;
		border-bottom: 1px solid $clre0;
		&::after {
			content: url(../../../../static/images/general/arrow-acc-white.svg);
			top: 15px;
			transform: rotate(180deg);
		}
		&:hover {background: $accent2;}
		&:active {background: $accent3;}
	}
	&::before {
		content: '';
		position: absolute;
		top: 12px;
		right: 10px;
		width: 26px;
		height: 26px;
		border: 1px solid $clre0;
		border-radius: 3px;
	}
	&::after {
		content: url(../../../../static/images/general/arrow-acc.svg);
		position: absolute;
		top: 17px;
		right: 15px;
		transition: .3s ease-out;
	}
	&:hover {
		&::after {
			content: url(../../../../static/images/general/arrow-acc-white.svg);
		}
	}
}