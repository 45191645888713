.s-basket {padding: 0 0 48px}

.b-basket {
	&__add-card {margin-bottom: 32px}
	&__basket-null {margin-top: 24px}
}

.add-card {
	&__search-field {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	&__button-field {
		position: relative;
		height: 54px;
		padding: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		&::after {
			content: 'Добавить';
			position: absolute;
			top: 16px;
			left: 0;
			right: 0;
		}
	}
	svg {
		display: none;
		stroke: $white;
	}
}

.basket-cards {
	&__item {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.s-basket {padding: 0 0 24px}
	
	.b-basket {
		&__add-card {margin-bottom: 24px}
		&__basket-null {margin-top: 0}
	}
}

@media only screen and (max-width : 576px) {
	.add-card {
		&__button-field {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: 54px;
			&::after {display: none}
		}
		svg {display: block}
	}
}