.s-text {
	padding: 48px 0;
	&__text {margin-bottom: 24px;}
	&__dwl-content {
		display: block;
		margin: 0 auto;
	}
}

@media only screen and (max-width : 768px) {
	.s-text {padding: 24px 0;}
}