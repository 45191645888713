.place-order {
	&__title {margin-bottom: 16px}
	&__content {
		background: #f8f8f8;
		padding: 26px;
	}
	&__item {
		&:not(:last-child) {margin-bottom: 16px}
	}
	&__button {
		display: block;
		max-width: 400px;
		height: 40px;
		margin: 24px auto 16px;
		padding: 8px 10px;
	}
}

@media only screen and (max-width : 576px) {
	.place-order {
		&__content {padding: 16px 14px}
	}
}