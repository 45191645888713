.product {
	&__item {
		width: 100%;
		height: 350px;
	}
	&__b-description {position: relative}
	&__article {margin-bottom: 20px}
	&__title {margin-bottom: 16px}
	&__presence {
		margin-bottom: 12px;
		svg {
			width: 17px;
			height: 15px;
			margin-right: 8px;
			fill: none;
			stroke: $accent;
			stroke-width: 2px;
		}
	}
	&__price {display: inline-block}
	&__buttons {
		margin-top: 24px;
	}
	&__basket {margin-right: 16px}
	&__download {
		position: absolute;
		top: -50px;
		right: 30px;
	}
}

.download {
	flex-shrink: 0;
	&__btn-crcl {margin-right: 16px}
	&__btn {
		svg {
			margin-left: 8px;
		}
	}
}

@media only screen and (max-width : 1199px) {
	.product {
		&__download {
			right: 15px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.product {
		&__slider {
			max-width: 350px;
			margin: 0 auto;
		}
		&__download {
			position: relative;
			top: 0;
			right: 0;
		}
	}
	.download {
		margin-left: 16px;
		&__btn-crcl {display: none !important}
	}
}

@media only screen and (max-width : 576px) {
	.product {
		&__basket {
			max-width: 290px;
			margin: 0;
		}
		&__fast {
			max-width: 290px;
			margin: 16px 0;
		}
	}
	.download {
		margin: 0;
		&__btn {max-width: 290px}
	}
}