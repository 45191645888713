.icon-hamburger {
	width: 24px;
	height: 14px;
	fill: $white;
}

.icon-mail {
	width: 20px;
	height: 14px;
	fill: $accent;
}

.icon-time {
	width: 15px;
	height: 15px;
	fill: $accent;
}

.icon-search {
	width: 26px;
	height: 26px;
	fill: $white;
}

.icon-phone {
	width: 26px;
	height: 26px;
	fill: $accent;
}

.icon-basket {
	width: 33px;
	height: 25px;
	fill: $white;
}

.icon-city {
	width: 12px;
	height: 16px;
	fill: $accent;
}

.icon-close {
	width: 18px;
	height: 18px;
	stroke: $text-dark;
	stroke-width: 2px;
}

.icon-dwl {
	width: 16px;
	height: 18px;
	fill: $white;
}

.icon-arrow-dwl {
	width: 14px;
	height: 16px;
	fill: $white;
}

.icon-learn-price {
    width: 13px;
    height: 21px;
    fill: $accent;
}

.icon-view, .icon-view-2 {
	width: 18px;
	height: 14px;
	fill: $text;
}

.icon-minus, .icon-plus {
	width: 20px;
	height: 20px;
	stroke: $clre0;
	stroke-width: 2px;
}

.icon-info, .icon-contacts, .icon-other, .icon-verify {
	width: 46px;
	height: 46px;
	fill: #c1c1c1;
}

.icon-milk {
	width: 25px;
	height: 46px;
	fill: #c1c1c1;
}

.icon-temperature {
	width: 24px;
	height: 46px;
	fill: #c1c1c1;
}

.icon-document {
	width: 21px;
	height: 26px;
	fill: $accent;
}

.icon-m-hamburger {
	width: 20px;
	height: 11px;
	fill: $white;
	stroke-width: 3px;
}