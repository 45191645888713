.m-filters {
	&__panel {
		display: none;
		padding: 10px;
		border: 1px solid $clre0;
	}
	&__value {
		display: none;
		margin-left: 4px;
	}
	&__list {
		max-height: 156px;
		overflow-y: scroll;
		&::-webkit-scrollbar-track {
			border-radius: 50px;
			background: #f5f5f5;
		}
		&::-webkit-scrollbar {
			width: 6px;
			background: #f5f5f5;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 50px;
			background: $accent;
		}
	}
	&__button {
		margin-top: 24px;
		color: $accent;
		text-align: center;
		font-size: 14px;
		cursor: pointer;
		&:hover {color: $accent2}
		&:active {color: $accent3}
	}
	.accordion {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 12px;
		padding-right: 12px !important;
		color: $text-dark;
		border-bottom: 1px solid $clre0;
		cursor: pointer;
		&::before {display: none}
		&::after {display: none}
		svg {
			width: 30px;
			height: 15px;
			transform: rotate(90deg);
			fill: none;
			stroke: $text-dark;
			stroke-width: 2px;
		}
		&.active {
			background: $white;
			color: $accent;
			border: none;
			svg {
				stroke: $accent;
				transform: rotate(-90deg);
			}
			.m-filters__value {
				display: inline-block;
			}
		}
	}
}