.news {
	&__item {
		&:hover {
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
		}
		&:active {
			.news-content::after {
				background: rgba(0, 0, 0, .4);
			}
		}
	}
	
	&__text {
		padding: 18px 16px;
		background: $clrf9;
		color: $text-dark;
	}
}

.news-content {
	position: relative;
	width: 100%;
	height: 188px;
	img {
		width: 100%;
		height: 188px;
	}
	&__header {
		position: absolute;
		top: 18px;
		left: 16px;
		right: 16px;
		bottom: 20px;
		color: $white;
		z-index: 10;
	}
	&__data {
		position: absolute;
		bottom: 0;
	}
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, .2);
		z-index: 1;
	}
}