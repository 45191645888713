ul:not([class]) {
	margin-bottom: 24px;
	padding-left: 18px;
	list-style-type: none;
	font-size: 16px;
	line-height: 150%;
	font-weight: 400;
	li {
		position: relative;
		margin-bottom: 10px;
		color: $text;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: -18px;
			width: 4px;
			height: 4px;
			background: $accent;
			border-radius: 50%;
			transform: translateY(-50%);
		}
		&:last-child {margin-bottom: 0}
	}
}

.input-srch {
	position: relative;
	width: 100%;
	height: 54px;
	padding: 18px 20px;
	color: $text;
	border: 1px solid $clre0;
	border-radius: 5px;
	font-size: 14px;
	&::placeholder {
		color: $clre0;
	}
	&:focus {
		&::after {
			display: none;
		}
	}
	&--big {
		max-width: 540px;
		font-size: 15px;
		font-weight: 500;
	}
	&--small {
		max-width: 320px;
	}	
	&--white {
		border-color: $white;
	}	
}

.checkbox {
	font-size: 14px;
	user-select: none;
	input {display: none}
	&__text {
		position: relative;
		display: flex;
		align-items: center;
		height: auto;
		line-height: 1.1;
		cursor: pointer;
		&::before {
			content: '';
			display: inline-block;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			margin: auto 16px auto 0;
			border: 1px solid #b5b5b5;
			border-radius: 3px;
		}
	}
	input[type=checkbox]:checked ~ span::before {
		background: $accent;
		border-color: $accent;
	}
	&--big {
		.checkbox__text {
			&::before {
				width: 34px;
				height: 34px;
			}
		}
	}
}

.radiobox {
	display: inline-block;
	height: 54px;
	user-select: none;
	input {display: none}
	.radiomark {
		display: inline-block;
		width: 54px;
		height: 54px;
		padding: 16px 0;
		background: #f9f9f9;
		text-align: center;
		cursor: pointer;
	}
	input[type=radio]:checked ~ .radiomark {
		background: $accent;
		color: $white;
	}
}

.textarea {
	width: 100%;
	height: 160px;
	padding: 18px 20px;
	color: $text;
	border: 1px solid $clre0;
	border-radius: 3px;
	font-size: 14px;
	font-weight: normal;
	&::placeholder {color: $clre0}	
	&--white {border-color: $white}
}
	

.input-label {
	position: relative;
	display: inline-block;
	margin-bottom: 16px;
	font-size: 18px;
	line-height: 125%;
	font-weight: normal;
	&--rqd {
		&::after {
			content: '*';
			position: absolute;
			top: 0;
			right: -10px;
			color: $accent;
		}
	}
}

.result {
	&__title {
		font-size: 18px;
		font-weight: 500;
		line-height: 150%;
		color: $text-dark;
	}
	&__output-value {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		min-width: 50px;
		height: 50px;
		margin-left: 16px;
		border: 1px solid $clre0;
		border-radius: 50px;
		text-align: center;
	}
}

.old-price {color: $clre0}

.new-price {
	color: $accent;
	span {color: $text;}
}

.learn-price {
	display: flex;
	align-items: center;
	text-decoration: underline;
	cursor: pointer;
	svg {
		margin-right: 9px;
	}
	&:hover {
		text-decoration: none;
		svg {fill: $accent2}
	}
	&:active {
		color: $accent3;
		svg {fill: $accent3}
	}
}

.article-card {
	display: flex;
	justify-content: flex-end;
	&__number {
		margin-left: 4px;
		color: $text-dark;
		font-weight: 700;
	}
}

.input-number {
	display: flex;
	max-width: 120px;
	width: 100%;
	&__field {
		width: 100%;
		height: 34px;
		border: 1px solid $clre0;
		border-left: none;
		border-right: none;
		text-align: center;
		border-radius: 0;
		-webkit-border-radius: 0;
	}
	&__minus, &__plus {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 36px;
		height: 34px;
		border: 1px solid $clre0;
		cursor: pointer;
		&:hover {
			svg {stroke: $accent}
		}
	}
	&__minus {
		border-right: none;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&__plus {
		border-left: none;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}

.clrf9 {background: #f9f9f9}

.tac {text-align: center}