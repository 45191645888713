.txt {
	line-height: 125%;
	letter-spacing: 4%;
	&--h1 {
		margin-bottom: 32px;
		font-size: 30px;
		line-height: 125%;
	}
	&--ds1 {
		margin-bottom: 32px;
		font-size: 30px;
		line-height: 125%;
	}
	&--ds2 {
		font-size: 24px;
		font-weight: 400;
		line-height: 125%;
	}
	&--ds3 {
		font-size: 16px;
		line-height: 150%;
		font-weight: 400;
	}
	&--ds4 {
		font-size: 14px;
		line-height: 150%;
		font-weight: 300;
	}
	&--bld32 {
		font-size: 32px;
		font-weight: bold;
	}
	&--bld24 {
		font-size: 24px;
		font-weight: bold;
	}
	&--bld18 {
		font-size: 18px;
		font-weight: bold;
	}
	&--bld14 {
		font-size: 14px;
		font-weight: bold;
	}
	&--mdm24 {
		font-size: 24px;
	}
	&--lgt16 {
		font-size: 16px;
		line-height: 150%;
		font-weight: 300;
	}
	&--fz18 {
		font-size: 18px;
	}
	&--s-title {
		font-size: 32px;
		margin-bottom: 32px;
	}
	&--clr1 {
		color: $accent;
		&:hover {
			color: $accent2;
		}
	}
	&--clr2 {
		color: $text-dark;
	}
	&--clr3 {
		color: $text;
	}
}

@media only screen and (max-width : 768px) {
	.txt {
		&--h1, &--ds1 {
			font-size: 24px;
			margin-bottom: 24px;
		}
	}
}