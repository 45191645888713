.contacts {
	&__list {
		position: absolute;
		top: 125px;
		left: 0;
		max-width: 495px;
		width: 100%;
		background: $white;
		flex-direction: column;
		justify-content: flex-end;
		border-radius: 3px;
		z-index: 100;
		li {
			width: 100%;
			padding: 10px 18px;
			border-bottom: 1px solid $clre0;
			&:last-child {border: none}
			div {
				&:first-child {
					margin-bottom: 4px;
					color: $clre0;
				}
			}
		}
	}
	&__map {
		position: relative;
		left: 50%;
		width: 100vw;
		height: 800px;
		margin-left: -50vw;
	}
}

@media only screen and (max-width : 768px) {
	.contacts {
		&__top-line {flex-direction: column}
		&__list {
			position: relative;
			top: 0;
			left: 50%;
			max-width: 100vw;
			width: 100vw;
			margin-top: 24px !important;
			margin-left: -50vw !important;
		}
		&__map {
			height: 450px;
		}
	}
}