footer { flex: 0 0 auto; }

.footer {
	background: $text-dark;
	color: $white;
	padding: 24px 0 32px;
	&__title {margin-bottom: 18px}
	&__logo {margin-bottom: 40px}
	&__phone {margin-bottom: 16px;}
	&__button {margin-top: 32px}
	&__list {
		li {
			display: flex;
			justify-content: space-between;
			margin-bottom: 16px;
			a {
				&:hover {color: $accent;}
			}
		}
	}
	&__text {
		margin-bottom: 24px;
	}
	.day, .time {white-space: nowrap;}
}

@media only screen and (max-width : 576px) {
	.footer {
		&__menu {display: none !important}
		&__button {margin-bottom: 24px}
	}
}