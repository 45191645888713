.submenu {
	a {
		width: 100%;
	}
	&__title {
		border: 1px solid $clre0;
		border-bottom: none;
		color: $accent;
		font-size: 13px;
		line-height: 150%;
		font-weight: 700;
		&:first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		&:last-child {
			border-bottom: 1px solid $clre0;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		a {
			&:hover {
				background: $accent;
				color: $white;
			}
			&:active {
				background: $accent2;
			}
		}
		
	}
	&__list {
		display: none;
		color: $text;
		li {
			&:hover {
				background: $accent;
				color: $white;
			}
		}
	}
	li {
		a {
			padding: 12px 10px;
		}
	}
}

@media only screen and (max-width : 768px) {
	.submenu-hidden {display: none}
}