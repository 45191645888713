.modal-open, .modal {padding-right: 0 !important}

.modal-dialog {
	&--wdh920 {max-width: 920px}
	&--wdh540 {max-width: 540px}
	&--wdh320 {max-width: 320px}
	&--wdh290 {max-width: 290px}
}
.modal-content {
	position: relative;
	border: none;
	border-radius: 15px;
}

.modal-header {
	position: relative;
	padding: 32px 20px;
	border-bottom-width: 3px;
	&__title {
		margin: 0;
	}
}

.modal-body {
	padding: 46px 66px;
	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		svg {
			width: 26px;
			height: 26px;
			stroke: $accent;
		}
		&:hover {
			svg {stroke: $accent2}
		}
		&:active {
			svg {stroke: $accent3}
		}
	}
	&__title {
		color: $text-dark;
		margin-bottom: 26px;
		font-size: 30px;
		font-weight: 700;
		line-height: 125%;
	}
	&__subtitle {
		margin-bottom: 12px;
		line-height: 150%;
	}
	&__product-title {
		margin-bottom: 24px;
		color: $text-dark;
		font-size: 20px;
		font-weight: 500;
		line-height: 150%;
	}
	&__text {
		color: $text-dark;
		font-size: 24px;
		line-height: 125%;
		margin-bottom: 16px;
	}
	&__modal-forms {
		width: 100%;
		margin-bottom: 24px;
		padding: 0 26px;
	}
	&__policy {
		line-height: 150%;
	}
	&__button {margin-top: 16px}
	&__basket {margin-right: 16px}
}

.modal-forms {
	&__item {
		&:not(:first-child) {margin-top: 24px}
	}
	&__button {
		display: block;
		max-width: 370px;
		margin: 48px auto 0;
		padding: 10px;
	}
}

.modal-city, .modal-add-product {
	padding: 20px 20px 32px;
}

.city-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 16px 40px;
	margin-top: 20px;
	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 42px;
		padding: 12px 10px;
		border: 1px solid $accent;
		border-radius: 50px;
		transition: .3s ease-out;
		cursor: pointer;
		&:hover {
			background: $accent;
			color: $white;
		}
	}
}

.modal-add-product {
	.modal-body__product-title {
		font-size: 32px;
		font-weight: 500;
		line-height: 125%;
	}
}

.modal-category {
	.modal-dialog {
		position: static;
		margin: 0;
	}
	.modal-content {
		position: static;
		min-height: 100vh;
		border-radius: 0;
	}
	.modal-body {
		position: static;
		padding: 0;
		&__close {
			position: absolute;
			top: 18px;
			right: 20px;
			color: $white;
			svg {
				stroke: $white;
				margin-left: 20px;
			}
			&:active {
				color: $accent;
				svg {stroke: $accent}
			}
		}
	}
}

.modal-filters {
	.modal-dialog {
		position: static;
		margin: 0;
	}
	.modal-content {
		position: static;
		min-height: 100vh;
		border-radius: 0;
	}
	.modal-body {
		position: static;
		padding: 0;
		&__close {
			position: relative;
			top: 0;
			right: 0;
			margin-bottom: 16px;
			padding: 16px 15px 0;
			color: $text-dark;
			text-align: right;
			svg {
				stroke: $text-dark;
				margin-left: 20px;
			}
			&:active {
				color: $accent;
				svg {stroke: $accent}
			}
		}
	}
}

.modal-search {
	.modal-dialog {
		max-width: 100%;
		margin: 15px;
	}
	.modal-header {
		padding: 16px;
		border-bottom-width: 1px;
		&__search {
			flex: 1;
			form {
				position: relative;
			}
			.icon-search {
				width: 18px;
				height: 18px;
				fill: #b8b8b8;
			}
			.input-srch {
				height: 40px;
				padding: 0 18px;
				padding-right: 26px;
				border: none;
			}
			.input-rst {
				position: absolute;
				top: 11px;
				right: 0;
				padding: 0;
				background: 0;
				border: none;
				line-height: 1;
			}
			.icon-close {display: none}
			.input-srch:focus + button .icon-close {
				display: block;
			}
			.input-srch:focus + button .icon-search {
				display: none;
			}
		}
	}
	.modal-body {
		padding: 0 0 24px;
		font-size: 14px;
		&__list-search {
			li {
				&:hover {
					background: #f9f9f9;
				}
			}
			a {
				padding: 12px 16px;
			}
		}
	}
}

.modal-backdrop.show {
	opacity: .65;
}

@media only screen and (max-width : 992px) {
	.modal-dialog {
		&--wdh920 {max-width: 540px}
	}
	.city-list {
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}
}

@media only screen and (max-width : 768px) {
	.modal-add-product {
		.modal-body__product-title {font-size: 24px}
	}
}

@media only screen and (max-width : 576px) {
	.modal-content {
		border-radius: 5px;
	}

	.modal-header {
		padding: 20px 15px;
		&__title {width: 90%}
	}
	
	.modal-body {
		padding: 20px 15px;
		&__close {
			right: 15px;
			svg {
				width: 18px;
				height: 18px;
			}
		}
		&__title {
			font-size: 24px;
			width: 90%;
		}
		&__product-title, &__text {
			text-align: left;
			font-size: 20px;
		}
		&__modal-forms {padding: 0}
		&__basket {
			margin: 0 0 16px;
		}
		.btn {max-width: 290px}
	}
	.modal-forms {
		&__button {margin-top: 24px}
	}
	.city-list {
		grid-template-columns: 1fr;
	}
	.modal-category {
		.modal-dialog {position: relative}
		.modal-content {position: relative}
		.modal-dialog {
			position: relative;
			margin: 16px auto;
		}
		.modal-body {
			&__close {
				position: relative;
				top: 0;
				right: 0;
				margin-bottom: 16px;
				padding: 16px 15px 0;
				color: $text-dark;
				text-align: right;
				svg {
					stroke: $text-dark;
					margin-left: 20px;
				}
			}
		}
	}
	.modal-filters {
		.modal-dialog {position: relative}
		.modal-content {position: relative}
		.modal-dialog {
			position: relative;
			margin: 16px auto;
		}
	}
}